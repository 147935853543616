import type { AxiosError } from "axios";

export function decodeError(err: AxiosError, instead: string) {
  const rec_err = err?.response?.data as any as any;
  instead += "*";
  if (rec_err) {
    const { detail, details, error, errors, password, email } = rec_err;
    let another_one = undefined;
    if (details && typeof details === "object") {
      Object.entries(details).every(([k, v]) => {
        another_one = `${String(k)}: ${String(v)}`;
        return false; // just break on first value
      });
    }
    const final_error_msg = another_one || detail || details || error || errors || password || email || instead;
    return typeof final_error_msg === typeof [] ? final_error_msg[0] || instead : final_error_msg || instead;
  }
  return instead;
}
